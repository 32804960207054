import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const { MEMBAREA_PORTAL } = process.env;

const SEO = ({ description, lang, meta, keywords, title }) => {
  const {
    site: { siteMetadata },
    wpgraphql: { generalSettings },
    metaImage: { publicURL: image },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        metaImage: file(relativePath: { eq: "indefense-icon.png" }) {
          publicURL
        }
        wpgraphql {
          generalSettings {
            title
            description
            url
          }
        }
      }
    `
  );

  const metaTitle = title || generalSettings.title || siteMetadata.title;
  const metaDescription =
    description || generalSettings.description || siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${generalSettings.title || siteMetadata.title}`}
      script={
        MEMBAREA_PORTAL
          ? [
              {
                type: 'text/javascript',
                innerHTML: `
          (function(i, s, o, g, r, a, m){
            i.MembAreaObject=r;
            i[r] = i[r] || function(){(i[r].p = i[r].p || []).push(arguments)},
            i[r].l =1 * new Date();
            a = s.createElement(o), m = s.getElementsByTagName(o)[0];
            a.src = g; m.parentNode.insertBefore(a, m);
          })(window, document, 'script', '${MEMBAREA_PORTAL}?v=' + (Math.round(Date.now()/1000/60)),'MembArea');
        `,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: `${siteMetadata.siteUrl}${image}`,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: `${siteMetadata.siteUrl}${image}`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(','),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: 'de',
  meta: [],
  keywords: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
