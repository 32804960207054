import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';

const FooterFlat = () => (
  <StaticQuery
    query={graphql`
      query NaviFlatFooter {
        wpgraphql {
          generalSettings {
            url
          }
          menuItems(where: { location: FOOTER_FLAT }) {
            nodes {
              id
              url
              label
            }
          }
        }
      }
    `}
    render={({
      wpgraphql: {
        generalSettings: { url: siteUrl },
        menuItems: { nodes },
      },
    }) =>
      nodes.length > 0 && (
        <div className="footer__flat">
          <div>
            <ul>
              {nodes.map(({ label, url }) => (
                <li key={url}>
                  <Link to={url.replace(siteUrl, '')}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
    }
  />
);

export default FooterFlat;
