import React, { useState } from 'react';
import { FaClock, FaMapMarkedAlt, FaPhone, FaEnvelope, FaWhatsapp, FaTimes } from 'react-icons/fa';
import { Link } from 'gatsby';

import useOverlay from '../../hook/overlay';

const MobileTab = () => {
  const [showWlayer, setShowWlayer] = useState(false);

  useOverlay(showWlayer);

  return (
    <>
      <div className="mobile__tab">
        <nav>
          <Link title="Zeiten" to="/indefense/zeiten">
            <FaClock />
          </Link>
          <a
            title="Anfart"
            rel="noopener noreferrer noopener"
            target="_blank"
            href="https://www.google.com/maps/dir//Kiesweg+22,+35396+Gie%C3%9Fen/@50.6707968,8.5113305,14z/data=!4m6!4m5!1m0!1m2!1m1!1s0x47bc5f51a1941e8b:0x306de38091a3b405!3e0"
          >
            <FaMapMarkedAlt />
          </a>
          <a title="Telefon" href="tel:+49 (176) 312 943 75">
            <FaPhone />
          </a>
          <a title="E-Mail" href="mailto:info@indefense.de">
            <FaEnvelope />
          </a>
          <button type="button" onClick={() => setShowWlayer(true)}>
            <FaWhatsapp />
          </button>
        </nav>
      </div>
      <div className={['mobile__wlayer', showWlayer && 'active'].filter(Boolean).join(' ')}>
        <button title="Schließen" type="button" onClick={() => setShowWlayer(false)}>
          <FaTimes />
        </button>
        <ul>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Kontakt - Frauenboxen"
              href="https://wa.me/491627228682"
            >
              <FaWhatsapp />
              <span>Frauen Fitness Boxen & Kinder Kickboxen</span>
              <small>Natascha Trifunofic</small>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Kontakt - Kickboxen"
              href="https://wa.me/4917631294375"
            >
              <FaWhatsapp />
              <span>Kinder Kickboxen, Erwachsenen Kickboxen, Kung Fu & Kids Krav Maga</span>
              <small>Nenad Trifunofic</small>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Gruppe - Kung Fu"
              href="https://chat.whatsapp.com/GxJaBUVphQM7UtHZaZdl6Q"
            >
              <FaWhatsapp />
              <span>Kung Fu</span>
              <small>Gruppe</small>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Gruppe - Gießen Kids Krav Maga"
              href="https://chat.whatsapp.com/KgT9GEZP9Wo4sGvRJj2foG"
            >
              <FaWhatsapp />
              <span>Gießen Kids Krav Maga</span>
              <small>Gruppe</small>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Gruppe - Indefense Team Kickboxen"
              href="https://chat.whatsapp.com/BW8qRuKbSaM5jeQwVPTyWV"
            >
              <FaWhatsapp />
              <span>Indefense Team Kickboxen</span>
              <small>Gruppe</small>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer noopener"
              target="_blank"
              title="WhatsApp Gruppe - Frauen Fitness Boxen"
              href="https://chat.whatsapp.com/AfGausvaFqz9OyNYaFWagl"
            >
              <FaWhatsapp />
              <span>Frauen Fitness Boxen</span>
              <small>Gruppe</small>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileTab;
