import React, { Component } from 'react';
import { Link } from 'gatsby';


class Privacy extends Component {
  constructor(props) {
    super(props);

    this.notice = React.createRef();
    this.placeholder = React.createRef();
  }

  componentDidMount() {
    const closed = document.cookie.indexOf('tracking__closed=true') > -1;
    const tracking = document.cookie.indexOf('tracking__cookie=true') > -1;
    this.setState({
      closed: closed || tracking,
    });
  }

  componentDidUpdate() {
    this.changeClass();
  }

  closeTracking = () => {
    document.cookie = 'tracking__closed=true; path=/;';
    this.setState({ closed: true });
  };

  acceptTracking = () => {
    document.cookie = 'tracking__cookie=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/;';
    if (window.trackingInit) window.trackingInit();
    this.setState({ closed: true });
  };

  changeClass() {
    const { closed } = this.state;
    const { current: notice } = this.notice;
    const { current: placeholder } = this.placeholder;

    if (!closed && !notice.classList.contains('tracking__notice--active')) {
      notice.classList.add('tracking__notice--active');
      placeholder.style.height = `${notice.offsetHeight}px`;
    } else {
      placeholder.style.height = 0;
      notice.classList.remove('tracking__notice--active');
    }
  }

  render() {
    return (
      <>
        <div className="tracking__placeholder" ref={this.placeholder} />
        <div className="tracking__notice" ref={this.notice}>
          <div className="container">
            <div>
              <span>
                Diese Website verwendet Tracking-Tools. Durch die weitere Nutzung dieser
                Website stimmen Sie der Verwendung von Tracking-Tools zu.
              </span>
              {
                <Link to="/datenschutzerklaerung">Weitere Informationen</Link>
              }
            </div>
            <div>
              <button
                type="button"
                className="tracking__close"
                onClick={this.closeTracking}
              >
                &times;
              </button>
              <button
                type="button"
                className="tracking__accept"
                onClick={this.acceptTracking}
              >
                Akzeptieren
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Privacy;
