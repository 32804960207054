import React, { PureComponent, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { FaChevronDown, FaFacebookF, FaClipboardList } from 'react-icons/fa';
import { StaticQuery, Link, graphql } from 'gatsby';

import Brand from './brand';

const isActive = (check) => (check ? 'active selected' : '');

const SubNavi = ({ list, siteUrl }) => {
  const toggle = useRef(null);

  const toggleMenu = (e) => {
    e.stopPropagation();
    const { current } = toggle;
    const { previousSibling: aDom } = current.previousSibling;
    aDom.classList.toggle('active');
  };

  useEffect(() => {
    const { current: ref } = toggle;
    const span = ref.parentNode.querySelector('li > span');

    ref.addEventListener('click', toggleMenu);
    if (span) {
      ref.parentNode.addEventListener('click', toggleMenu);
    }
    return () => {
      ref.removeEventListener('click', toggleMenu);
      if (span) {
        ref.parentNode.removeEventListener('click', toggleMenu);
      }
    };
  }, []);

  return (
    <>
      <ul>
        {list.map((sub) => (
          <li key={sub.id}>
            <Link
              activeClassName="active"
              to={sub.url.replace(siteUrl, '')}
              dangerouslySetInnerHTML={{ __html: sub.label }}
            />
          </li>
        ))}
      </ul>
      <i ref={toggle}>
        {/* <svg /> */}
        <FaChevronDown />
      </i>
    </>
  );
};

SubNavi.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  siteUrl: PropTypes.string.isRequired,
};

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.header = React.createRef();
    this.close = React.createRef();
    this.leftNavi = React.createRef();
  }

  componentDidMount() {
    const { current: close } = this.close;
    window.addEventListener('scroll', this.checkScroll);
    close.addEventListener('click', this.toggleNavi);
    this.checkScroll();
  }

  componentWillUnmount() {
    const { current: close } = this.close;
    close.addEventListener('click', this.toggleNavi);
    window.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current: header } = this.header;

    if (top && !header.classList.contains('header--active')) {
      header.classList.add('header--active');
    } else if (!top) {
      header.classList.remove('header--active');
    }
  };

  toggleNavi = () => {
    const { current: close } = this.close;
    const { current: leftNavi } = this.leftNavi;
    const { body } = document;

    if (close.classList.contains('sandwich--close')) {
      close.classList.remove('sandwich--close');
      leftNavi.classList.remove('header__navi--active');
      body.classList.remove('body__scroll--disabled');
    } else {
      close.classList.add('sandwich--close');
      leftNavi.classList.add('header__navi--active');
      body.classList.add('body__scroll--disabled');
    }
  };

  render() {
    return (
      <header className="header" ref={this.header}>
        <StaticQuery
          query={graphql`
            query NaviTop {
              wpgraphql {
                generalSettings {
                  url
                }
                menuItems(first: 99, where: { location: HEADER_TOPBAR }) {
                  nodes {
                    id
                    label
                    url
                    parentId
                    childItems {
                      nodes {
                        id
                        label
                        url
                      }
                    }
                  }
                }
              }
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={({
            wpgraphql: {
              menuItems: { nodes },
              generalSettings: { url: siteUrl },
            },
            site: { siteMetadata },
          }) => {
            if (!nodes.length) {
              return (
                <Link className="header__brand" to="/" title={siteMetadata.title}>
                  <Brand title={siteMetadata.title} />
                </Link>
              );
            }

            return (
              <div className="header__container">
                <div>
                  <div className="sandwich" ref={this.close}>
                    <i />
                  </div>
                  <ul className="header__navi header__navi--left" ref={this.leftNavi}>
                    {nodes
                      .filter(({ parentId }) => !parentId)
                      .map(({ url, id, label, childItems }) => (
                        <li key={id}>
                          {url.indexOf('#') === 0 ? (
                            <Location>
                              {({ location: { pathname } }) => (
                                <span className={isActive(pathname.indexOf(url.replace('#', '/')) === 0)}>{label}</span>
                              )}
                            </Location>
                          ) : (
                            <Link to={url.replace(siteUrl, '')} activeClassName="active selected" partiallyActive>
                              <span>{label}</span>
                            </Link>
                          )}
                          {childItems.nodes.length > 0 && <SubNavi list={childItems.nodes} siteUrl={siteUrl} />}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="header__navi--center">
                  <Brand title={siteMetadata.title} />
                </div>
                <div>
                  <ul className="header__navi header__navi--right" ref={this.rightNavi}>
                    <li>
                      <a className="header__icon" href="https://www.facebook.com/Indefense-162333580454552/">
                        <FaFacebookF style={{ color: '#000' }} />
                      </a>
                    </li>
                    {/*
                    <li>
                      {/ * tailwind: header__button--ripple * /}
                      <Ripple className="header__button">
                        <FaClipboardList />
                        <span>Probetraining</span>
                      </Ripple>
                    </li>
                    */}
                  </ul>
                </div>
              </div>
            );
          }}
        />
      </header>
    );
  }
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
