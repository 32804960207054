import { useLayoutEffect } from 'react';

export default function useOverlay(toggle) {
  useLayoutEffect(() => {
    const { classList } = document.body;
    if (!classList) {
      return;
    }

    if (toggle) {
      classList.add('overlay');
    } else {
      classList.remove('overlay');
    }

    return () => classList.remove('overlay');
  }, [toggle]);
}
